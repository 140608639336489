// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function name(t) {
  return t.name;
}

function id(t) {
  return t.id;
}

function canReview(t) {
  return t.canReview;
}

function isAuthor(t) {
  return t.isAuthor;
}

function description(t) {
  return t.description;
}

function exited(t) {
  return t.exited;
}

function thumbnailUrl(t) {
  return t.thumbnailUrl;
}

function linkedCommunities(t) {
  return t.linkedCommunities;
}

function ended(t) {
  return t.ended;
}

function enableLeaderboard(t) {
  return t.enableLeaderboard;
}

function isStudent(t) {
  return t.isStudent;
}

function decodeCommunity(json) {
  return {
          communityName: Json_decode.field("name", Json_decode.string, json),
          communityId: Json_decode.field("id", Json_decode.string, json)
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          canReview: Json_decode.field("canReview", Json_decode.bool, json),
          isAuthor: Json_decode.field("isAuthor", Json_decode.bool, json),
          enableLeaderboard: Json_decode.field("enableLeaderboard", Json_decode.bool, json),
          description: Json_decode.field("description", Json_decode.string, json),
          exited: Json_decode.field("exited", Json_decode.bool, json),
          thumbnailUrl: Caml_option.null_to_opt(Json_decode.field("thumbnailUrl", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          linkedCommunities: Json_decode.field("linkedCommunities", (function (param) {
                  return Json_decode.array(decodeCommunity, param);
                }), json),
          ended: Json_decode.field("ended", Json_decode.bool, json),
          isStudent: Json_decode.field("isStudent", Json_decode.bool, json)
        };
}

export {
  name ,
  id ,
  canReview ,
  isAuthor ,
  description ,
  exited ,
  thumbnailUrl ,
  linkedCommunities ,
  ended ,
  enableLeaderboard ,
  isStudent ,
  decodeCommunity ,
  decode ,
}
/* No side effect */
