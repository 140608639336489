// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_array from "../../../../../../shared/node_modules/rescript/lib/es6/caml_array.js";

function handleScrollLock(add) {
  var classes = add ? "overflow-hidden" : "";
  var body = Array.prototype.slice.call(document.getElementsByTagName("body"));
  Caml_array.get(body, 0).className = classes;
}

function activate(param) {
  handleScrollLock(true);
}

function deactivate(param) {
  handleScrollLock(false);
}

export {
  handleScrollLock ,
  activate ,
  deactivate ,
}
/* No side effect */
