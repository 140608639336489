// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../../../shared/node_modules/rescript/lib/es6/string.js";

var urlRegex = new RegExp("^(?:(?:(?:https?|ftp):)?\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff]\\.)+(?:[a-z\\u00a1-\\uffff]{2,}\\.?))(?::\\d{2,5})?(?:[/?#]\\S*)?$", "i");

function isInvalid(allowBlank, url) {
  if ($$String.trim(url).length !== 0) {
    return !urlRegex.test(url);
  } else {
    return !allowBlank;
  }
}

function isValid(allowBlank, url) {
  return !isInvalid(allowBlank, url);
}

export {
  urlRegex ,
  isInvalid ,
  isValid ,
}
/* urlRegex Not a pure module */
