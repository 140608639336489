// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "./utils/I18n.bs.js";
import * as Curry from "../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "./components/FaIcon.bs.js";
import * as Js_promise from "../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "./utils/GraphqlQuery.bs.js";
import * as $$Notification from "./Notification.bs.js";
import * as WebPushSubscription from "./webPushSubscription";

var partial_arg = "components.WebPushSubscriptionManager";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function getWebPushData(prim) {
  return WebPushSubscription.getWebPushData();
}

function str(prim) {
  return prim;
}

function reducer(state, action) {
  switch (action) {
    case /* SetStatusSubscribed */0 :
        return {
                saving: false,
                status: /* Subscribed */0
              };
    case /* SetStatusUnSubscribed */1 :
        return {
                saving: false,
                status: /* UnSubscribed */1
              };
    case /* SetStatusSubscribedOnAnotherDevice */2 :
        return {
                saving: false,
                status: /* SubscribedOnAnotherDevice */2
              };
    case /* SetSaving */3 :
        return {
                saving: true,
                status: state.status
              };
    case /* ClearSaving */4 :
        return {
                saving: false,
                status: state.status
              };
    
  }
}

var Raw = {};

var query = "mutation CreateWebPushSubscriptionMutation($endpoint: String!, $p256dh: String!, $auth: String!)  {\ncreateWebPushSubscription(endpoint: $endpoint, p256dh: $p256dh, auth: $auth)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.createWebPushSubscription;
  return {
          createWebPushSubscription: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.createWebPushSubscription;
  var value$2 = value$1.success;
  var createWebPushSubscription = {
    success: value$2
  };
  return {
          createWebPushSubscription: createWebPushSubscription
        };
}

function serializeVariables(inp) {
  return {
          endpoint: inp.endpoint,
          p256dh: inp.p256dh,
          auth: inp.auth
        };
}

function makeVariables(endpoint, p256dh, auth, param) {
  return {
          endpoint: endpoint,
          p256dh: p256dh,
          auth: auth
        };
}

var CreateWebPushSubscriptionMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateWebPushSubscriptionMutation_Graphql_error = include.Graphql_error;

var CreateWebPushSubscriptionMutation_decodeNotification = include.decodeNotification;

var CreateWebPushSubscriptionMutation_decodeObject = include.decodeObject;

var CreateWebPushSubscriptionMutation_decodeNotifications = include.decodeNotifications;

var CreateWebPushSubscriptionMutation_decodeErrors = include.decodeErrors;

var CreateWebPushSubscriptionMutation_flashNotifications = include.flashNotifications;

var CreateWebPushSubscriptionMutation_sendQuery = include.sendQuery;

var CreateWebPushSubscriptionMutation_query = include.query;

var CreateWebPushSubscriptionMutation_fetch = include.$$fetch;

var CreateWebPushSubscriptionMutation = {
  CreateWebPushSubscriptionMutation_inner: CreateWebPushSubscriptionMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateWebPushSubscriptionMutation_Graphql_error,
  decodeNotification: CreateWebPushSubscriptionMutation_decodeNotification,
  decodeObject: CreateWebPushSubscriptionMutation_decodeObject,
  decodeNotifications: CreateWebPushSubscriptionMutation_decodeNotifications,
  decodeErrors: CreateWebPushSubscriptionMutation_decodeErrors,
  flashNotifications: CreateWebPushSubscriptionMutation_flashNotifications,
  sendQuery: CreateWebPushSubscriptionMutation_sendQuery,
  query: CreateWebPushSubscriptionMutation_query,
  $$fetch: CreateWebPushSubscriptionMutation_fetch,
  make: make
};

var Raw$1 = {};

var query$1 = "mutation DeleteWebPushSubscriptionMutation  {\ndeleteWebPushSubscription  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.deleteWebPushSubscription;
  return {
          deleteWebPushSubscription: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.deleteWebPushSubscription;
  var value$2 = value$1.success;
  var deleteWebPushSubscription = {
    success: value$2
  };
  return {
          deleteWebPushSubscription: deleteWebPushSubscription
        };
}

function serializeVariables$1(param) {
  
}

function makeVariables$1(param) {
  
}

function makeDefaultVariables(param) {
  
}

var DeleteWebPushSubscriptionMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$1 = include$1.make;

var DeleteWebPushSubscriptionMutation_Graphql_error = include$1.Graphql_error;

var DeleteWebPushSubscriptionMutation_decodeNotification = include$1.decodeNotification;

var DeleteWebPushSubscriptionMutation_decodeObject = include$1.decodeObject;

var DeleteWebPushSubscriptionMutation_decodeNotifications = include$1.decodeNotifications;

var DeleteWebPushSubscriptionMutation_decodeErrors = include$1.decodeErrors;

var DeleteWebPushSubscriptionMutation_flashNotifications = include$1.flashNotifications;

var DeleteWebPushSubscriptionMutation_sendQuery = include$1.sendQuery;

var DeleteWebPushSubscriptionMutation_query = include$1.query;

var DeleteWebPushSubscriptionMutation_fetch = include$1.$$fetch;

var DeleteWebPushSubscriptionMutation = {
  DeleteWebPushSubscriptionMutation_inner: DeleteWebPushSubscriptionMutation_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables,
  Graphql_error: DeleteWebPushSubscriptionMutation_Graphql_error,
  decodeNotification: DeleteWebPushSubscriptionMutation_decodeNotification,
  decodeObject: DeleteWebPushSubscriptionMutation_decodeObject,
  decodeNotifications: DeleteWebPushSubscriptionMutation_decodeNotifications,
  decodeErrors: DeleteWebPushSubscriptionMutation_decodeErrors,
  flashNotifications: DeleteWebPushSubscriptionMutation_flashNotifications,
  sendQuery: DeleteWebPushSubscriptionMutation_sendQuery,
  query: DeleteWebPushSubscriptionMutation_query,
  $$fetch: DeleteWebPushSubscriptionMutation_fetch,
  make: make$1
};

function deleteSubscription(send, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* SetSaving */3);
  Js_promise.$$catch((function (param) {
          Curry._1(send, /* ClearSaving */4);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (response) {
              if (response.deleteWebPushSubscription.success) {
                Curry._1(send, /* SetStatusUnSubscribed */1);
              } else {
                Curry._1(send, /* ClearSaving */4);
              }
              return Promise.resolve(undefined);
            }), Curry._3(make$1, undefined, undefined, undefined)));
}

function saveSubscription(subscription, send) {
  Curry._1(send, /* SetSaving */3);
  Js_promise.$$catch((function (param) {
          Curry._1(send, /* ClearSaving */4);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (response) {
              if (response.createWebPushSubscription.success) {
                Curry._1(send, /* SetStatusSubscribed */0);
              } else {
                Curry._1(send, /* ClearSaving */4);
              }
              return Promise.resolve(undefined);
            }), Curry._3(make, undefined, undefined, {
                endpoint: subscription.endpoint,
                p256dh: subscription.p256dh,
                auth: subscription.auth
              })));
}

function handleNotificationBlock(param) {
  $$Notification.error(t(undefined, undefined, "notification_rejected"), t(undefined, undefined, "notification_rejected_message"));
}

function createSubscription(send, $$event) {
  $$event.preventDefault();
  Js_promise.$$catch((function (param) {
          Curry._1(send, /* ClearSaving */4);
          handleNotificationBlock(undefined);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (r) {
              if (r == null) {
                handleNotificationBlock(undefined);
              } else {
                saveSubscription(r, send);
              }
              return Promise.resolve(undefined);
            }), WebPushSubscription.createSubscription()));
}

var webPushEndpoint = document.documentElement.getAttribute("data-subscription-endpoint");

var webPushEndpoint$1 = (webPushEndpoint == null) ? undefined : Caml_option.some(webPushEndpoint);

function loadStatus(status, send, param) {
  if (status !== 1) {
    Js_promise.$$catch((function (param) {
            return Promise.resolve(undefined);
          }), Js_promise.then_((function (r) {
                if ((webPushEndpoint == null) || webPushEndpoint === "") {
                  Curry._1(send, /* SetStatusUnSubscribed */1);
                } else if (r == null) {
                  Curry._1(send, /* SetStatusSubscribedOnAnotherDevice */2);
                } else {
                  Curry._1(send, webPushEndpoint === r.endpoint ? /* SetStatusSubscribed */0 : /* SetStatusSubscribedOnAnotherDevice */2);
                }
                return Promise.resolve(undefined);
              }), WebPushSubscription.getWebPushData()));
  }
  
}

function computeInitialState(param) {
  var tmp;
  tmp = (webPushEndpoint == null) || webPushEndpoint === "" ? /* UnSubscribed */1 : /* Subscribed */0;
  return {
          saving: false,
          status: tmp
        };
}

function button(saving, onClick, icon, text) {
  return React.createElement("button", {
              className: "inline-flex items-center font-semibold px-2 py-1 md:py-1 bg-gray-50 hover:bg-gray-300 focus:bg-gray-300 border rounded text-xs shrink-0",
              disabled: saving,
              onClick: onClick
            }, React.createElement(FaIcon.make, {
                  classes: "me-2 fa-fw fas fa-" + (
                    saving ? "spinner fa-spin" : icon
                  )
                }), text);
}

function WebPushSubscriptionManager(Props) {
  var match = React.useReducer(reducer, computeInitialState(undefined));
  var send = match[1];
  var state = match[0];
  var partial_arg = state.status;
  React.useEffect((function () {
          var param;
          return loadStatus(partial_arg, send, param);
        }), []);
  var match$1 = state.status;
  switch (match$1) {
    case /* Subscribed */0 :
        return button(state.saving, (function (param) {
                      return deleteSubscription(send, param);
                    }), "bell-slash", t(undefined, undefined, "unsubscribe"));
    case /* UnSubscribed */1 :
        return button(state.saving, (function (param) {
                      return createSubscription(send, param);
                    }), "bell", t(undefined, undefined, "subscribe"));
    case /* SubscribedOnAnotherDevice */2 :
        return React.createElement("div", undefined, button(state.saving, (function (param) {
                          return createSubscription(send, param);
                        }), "bell", t(undefined, undefined, "subscribed_on_another_device")));
    
  }
}

var make$2 = WebPushSubscriptionManager;

export {
  t ,
  getWebPushData ,
  str ,
  reducer ,
  CreateWebPushSubscriptionMutation ,
  DeleteWebPushSubscriptionMutation ,
  deleteSubscription ,
  saveSubscription ,
  handleNotificationBlock ,
  createSubscription ,
  webPushEndpoint$1 as webPushEndpoint,
  loadStatus ,
  computeInitialState ,
  button ,
  make$2 as make,
}
/* include Not a pure module */
