// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../utils/DateFns.bs.js";
import * as Belt_Option from "../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function make(id, name, description, endsAt, courseId) {
  return {
          id: id,
          name: name,
          description: description,
          endsAt: endsAt,
          courseId: courseId
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function description(t) {
  return t.description;
}

function endsAt(t) {
  return t.endsAt;
}

function courseId(t) {
  return t.courseId;
}

function makeFromJs(cohort) {
  var partial_arg = Belt_Option.map(cohort.endsAt, DateFns.decodeISO);
  var partial_arg$1 = cohort.description;
  var partial_arg$2 = cohort.name;
  var partial_arg$3 = cohort.id;
  return function (param) {
    return make(partial_arg$3, partial_arg$2, partial_arg$1, partial_arg, param);
  };
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          endsAt: Json_decode.optional((function (param) {
                  return Json_decode.field("endsAt", DateFns.decodeISO, param);
                }), json),
          courseId: Json_decode.field("courseId", Json_decode.string, json)
        };
}

function filterValue(t) {
  return t.id + ";" + t.name;
}

var Raw = {};

function parse(value) {
  var value$1 = value.description;
  var value$2 = value.endsAt;
  return {
          id: value.id,
          name: value.name,
          description: !(value$1 == null) ? value$1 : undefined,
          endsAt: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
          courseId: value.courseId
        };
}

function serialize(value) {
  var value$1 = value.courseId;
  var value$2 = value.endsAt;
  var endsAt = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
  var value$3 = value.description;
  var description = value$3 !== undefined ? value$3 : null;
  var value$4 = value.name;
  var value$5 = value.id;
  return {
          id: value$5,
          name: value$4,
          description: description,
          endsAt: endsAt,
          courseId: value$1
        };
}

function verifyArgsAndParse(_CohortFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Fragment = {
  Raw: Raw,
  query: "fragment CohortFragment on Cohort   {\nid  \nname  \ndescription  \nendsAt  \ncourseId  \n}\n",
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

function makeFromFragment(cohort) {
  return make(cohort.id, cohort.name, cohort.description, Belt_Option.map(cohort.endsAt, DateFns.decodeISO), cohort.courseId);
}

export {
  make ,
  id ,
  name ,
  description ,
  endsAt ,
  courseId ,
  makeFromJs ,
  decode ,
  filterValue ,
  Fragment ,
  makeFromFragment ,
}
/* DateFns Not a pure module */
