// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../../../shared/node_modules/rescript/lib/es6/string.js";

var regularExpression = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

function isInvalid(allowBlank, email) {
  if ($$String.trim(email).length !== 0) {
    return !regularExpression.test(email);
  } else {
    return !allowBlank;
  }
}

export {
  regularExpression ,
  isInvalid ,
}
/* No side effect */
